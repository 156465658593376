import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus, { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';

import locales from './locales';
import zhCn from 'element-plus/es/locale/lang/zh-cn';

import { TUIComponents, TUICore } from './TUIKit';
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import { TUINotification } from './TUIKit/TUIPlugin';
import instance from '@/api';
import TIM from 'tim-js-sdk/tim-js-friendship';

const SDKAppID = 1400816569; // 正式
const secretKey = 'b2b3a1fdf687b82b4fc46de39ad472f9108489d90cf5bf4752e9ccb48d865e30'; // Your secretKey

// const SDKAppID = 1400821932; // 测试
// const secretKey = '4498a70269ca4f34ffaac3a3f82e26b0fdf8ee31970c42bfdc0f8af4b9a5cfb1'; // Your secretKey


const TUIKit = TUICore.init({
  SDKAppID,
});
TUIKit.tim.setLogLevel(3);

window.addEventListener('message', (event) => {
  // console.log(event);
  const { data } = event;
  switch (data.iframeClick) {
    case 'getUserInfo':
      // console.log(data.params.key);
      // console.log(data.params.userID);
      localStorage.setItem('token', data.params.key);
      localStorage.setItem('userID', data.params.userID);
      localStorage.setItem('lawyerStatus', data.params.lawyerStatus);
      const userId = data.params.userID;
      instance({
        url: `/api/im/userSig?userId=${userId}`,
        method: 'get',
        withCredentials: true,
      })
        .then((response: any) => {
          // console.log(response);
          if (response.code == 1) {
            const userSig = response.data;
            TUIKit.login({ userID: userId, userSig })
              .then((res: any) => {
                // const options = {
                //   ...userInfo,
                //   expire: new Date().getTime() + EXPIRETIME * 1000,
                // };
                // store.commit('setUserInfo', options);
                localStorage.setItem('isLogin', 'true');
              })
              .catch((error: any) => {
                ElMessage({
                  message: error,
                  grouping: true,
                  type: 'error',
                });
              });
          }
        })
        .catch((error: any) => {
          ElMessage({
            message: error,
            grouping: true,
            type: 'error',
          });
        });
  }
});


TUIKit.tim.on(TIM.EVENT.SDK_READY, () => {
  TUIKit.config.i18n.provideMessage(locales);

  TUIKit.use(TUIComponents);
  TUIKit.use(TUICallKit);
  TUIKit.use(TUINotification);
  const app = createApp(App);
  app.use(store)
    .use(router)
    .use(TUIKit)
    .use(ElementPlus, { locale: zhCn })
    .mount('#app');
});

export { SDKAppID, secretKey };
