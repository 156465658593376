import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'Home' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/onlineChatWorking',
    name: 'OnlineChatWorking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OnlineChatWorking.vue'),
  },
  {
    path: '/chatHistoryList',
    name: 'ChatHistoryList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatHistoryList.vue'),
  },
  // {
  //   path: '/informationCard',
  //   name: 'InformationCard',
  //   component: () => import(/* webpackChunkName: "about" */ '../TUIKit/TUIComponents/container/TUIChat/plugin-components/informationCard/InformationCardDetail.vue'),
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !(TUICore as any).isLogin) {
//     next({ name: 'Login' });
//   } else {
//     next();
//   }
// });

export default router;
