import axios from 'axios';
// export const baseURL = 'http://192.168.5.157:8108';
// export const baseURL = 'https://app.elvbang.com';
export const baseURL = '';

const instance = axios.create({
  baseURL: `${baseURL}`,
  timeout: 50000,
  // headers: { 'content-type': 'application/x-www-form-urlencoded' },
});

// 添加请求拦截器,
instance.interceptors.request.use(request => {
      // 设置请求的头信息
	  request.headers.set('X-Token', localStorage.getItem('token')!);
      return request;
    },
    (error: any) => {
      Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use((response: any) => {
    if (response.data.code === 201) {
        window.parent.postMessage({
            optType: 'logout',
        }, '*');
    }
      return response.data;
    },
    (error: any) => {
      Promise.reject(error);
    }
);


export default instance;
